@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.swiper_container {
    width: 100%;
    height: 150px;
}

.swiper_slide {
    font-size: 12px;
    // color: variables.$greyD;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    // user-select: none;
    opacity: 0.25;
    transition: opacity 0.3s ease;
    cursor: default;
    font-weight: bold;
    -webkit-tap-highlight-color: transparent;
}

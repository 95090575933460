@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.not_found {
    padding: 10px;
    min-height: 50vh;
    @include mixin.itemsCenter;
    justify-content: center;
    flex-direction: column;

    img {
        max-width: 244px;
    }

    svg {
        width: 200px;
        height: 200px;
    }

    .content {
        @include mixin.itemsCenter;
        justify-content: center;
        flex-direction: column;
        p {
            background-color: variables.$secondary200;
            padding: 10px;
            font-size: 16px;
            font-weight: 500;
            position: relative;
            @include mixin.borderRadius;

            &::before,
            &::after {
                content: "";
                display: inline-block;
                width: 15px;
                height: 1.5px;
                //
                vertical-align: middle;
                background-color: variables.$dark;
            }
            &::before {
                margin-right: 10px;
            }
            &::after {
                margin-left: 10px;
            }
        }
        span {
            font-weight: 400;
            font-size: 14px;
            color: variables.$greyD;
        }
    }
}

.fix_min_height {
    min-height: 70vh;
    // background-color: red;
}

@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.product_list_warp_banner {
    // background-color:variables.$grey200;
    padding-top: 12px;
}

.product_list_warp {
    // background-color:variables.$grey200;
    padding-top: 7px;
}

.product_header_full {
    // @include mixin.itemsCenter;
    width: 100%;
    display: flex;
    padding-left: 10px;
    flex-direction: column;

    h3 {
        margin: 0;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 3px;
    }

    & > p {
        font-size: 10px;
        font-weight: 400;
        color: variables.$greyD;
    }
}

@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.range_slider {
    padding: 0 12px 0 5px;
    label {
        font-size: 12px;
        font-weight: 500;
    }

    .slider {
        margin-top: 25px;
    }

    .max_min_input {
        margin-top: 30px;
    }
}

.max_min_input {
    @include mixin.itemsCenter;
    justify-content: space-between;

    .divider {
        // max-width: 20px;
        padding: 0 10px;
    }

    input {
        width: 100%;
    }
}

.floatingLabelInput {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    .currencySymbol {
        position: absolute;
        left: 10px;
        font-size: 14px;
    }

    label {
        position: absolute;
        left: 22px;
        top: 24%;
        transition:
            top 0.3s,
            font-size 0.3s;
        font-size: 14px;
    }

    .input {
        width: 100%;
        padding: 8px 20px;
        font-size: 14px;
        border-radius: 6px;
        border: 1px solid #e0e0e0;
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &::placeholder {
            font-size: 14px;
        }

        &:focus {
            outline: none;
            border: 1px solid variables.$primary;
        }
    }

    .focused {
        top: -20px;
        font-size: 12px;
    }
}

@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.title_wrap {
    padding-bottom: 10px;

    .title {
        @include mixin.flexBetween;
        span {
            font-size: 14px;
            font-weight: 600;
            @include mixin.itemsCenter;
        }
        a {
            color: variables.$dark;
            text-decoration: none;
            // background-color: variables.$orange;
            font-size: 13px;
            font-weight: 700;
            @include mixin.borderRadius;
            padding: 5px;
        }
    }
    span {
        font-size: 12px;
        font-weight: 500;
        display: block;
    }
    .text_primary {
        color: variables.$primary;
    }
    .text_error {
        color: variables.$error;
    }
}

.bottom_link {
    padding-bottom: 0;
    color: variables.$dark;

    .bottom_link_btn {
        @include mixin.flexBetween;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        padding: 12px variables.$cp;
        color: variables.$dark;

        span {
            @include mixin.itemsCenter;

            svg {
                @include mixin.itemsCenter;
                // rotate: 180deg;
                width: 24px;
                height: 24px;
            }
        }
    }
}

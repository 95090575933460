@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.shear_btn_wrp {
    @include mixin.itemsCenter;
    button {
        @include mixin.itemsCenter;
        margin: 0 10px;
        padding: 0;

        &:hover {
            svg {
                color: variables.$primary;
            }
        }
        svg {
            width: 24px;
            height: 24px;
            width: 100%;
            height: 100%;
            @include mixin.itemsCenter;
            color: variables.$greyD;
        }
    }
}

@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.product_variants {
    // padding-bottom: 1px;

    .variants_details {
        @include mixin.itemsCenter;
        border-bottom: 1px solid variables.$grey200;
        // padding-bottom: 10px;
        // margin-bottom: 10px;

        .img_wrap {
            min-width: 75px;
            min-height: 75px;
            max-width: 75px;
            max-height: 75px;
            @include mixin.borderRadius;
            border: 1px solid variables.$grey200;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .content {
            padding-left: 10px;

            h3 {
                font-size: 12px;
                font-weight: 700;
                margin: 0;
            }
            h4 {
                margin: 5px 0;
                del {
                    font-size: 10px;
                    color: variables.$grey300;
                }
                span {
                    font-size: 12px;
                    color: variables.$error;
                    font-weight: 700;
                    margin-left: 5px;
                }
                b {
                    font-size: 14px;
                    margin-left: 10px;
                }
            }
            p {
                margin: 0;
                font-size: 12px;
            }
        }
    }
    .v_wrap {
        // margin-bottom: 10px;
        h3 {
            font-size: 12px;
            font-weight: 600;
            margin: 0;

            span {
                color: variables.$dark;
            }
        }

        .v_button {
            padding: 0 9px !important;

            .button {
                background-color: transparent;
                border: 1px solid variables.$grey200;
                @include mixin.borderRadius;
                font-size: 10px;
                font-weight: 500;
                padding: 10px;
                width: 100%;
                height: 100%;
            }

            .active {
                background-color: rgba($color: variables.$primary, $alpha: 0.1);
                border: 1px solid variables.$primary;
                color: variables.$primary;
            }
            .button_disabled {
                position: relative;
                overflow: hidden;
                &::after {
                    content: "";
                    position: absolute;
                    top: 16px;
                    left: 0;
                    rotate: 19deg;
                    width: 110%;
                    height: 1px;
                    @include mixin.borderRadius;
                    background-color: variables.$grey300;
                }
            }
        }
    }
    // .add_cart_wrp {
    //     padding-top: 20px;
    //     margin-bottom: 10px;
    //     button {
    //         width: 100%;
    //         max-width: 100% !important;
    //         padding: 10px;
    //         font-size: 14px;
    //     }
    // }
}

@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.product_wrap {
    // background: #ffffff;
    // border: 1px solid #eeeff2;
    border-radius: 6px;
    height: 100%;
    overflow: hidden;

    a {
        color: variables.$dark;
    }

    .product {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .img_wrap {
            // @include mixin.flexCenter;
            // text-align: center;

            background-color: variables.$white;
            border: 1px solid #eeeff2;
            border-radius: 10px;
            // @include mixin.borderRadius;

            font-size: 12px;
            position: relative;
            min-height: 120px;
            width: 100%;
            overflow: hidden;

            .out_off_stock {
                //old design
                @include mixin.absolute(10px, auto, auto, 0);
                background-color: variables.$white;
                // z-index: 3;
                font-size: 10px;
                color: variables.$error;
                border-radius: 0px 12px 12px 0px;
                border: 1px solid variables.$error;
                // box-shadow: 1px 0 1px variables.$error;
                padding-left: 0;
                padding: 2px 5px;
                font-weight: 700;
                list-style: none;
                z-index: 9;
            }
            span {
                @include mixin.absolute(auto, auto, 0, 0);
                width: 60px;
                height: 18px;
                background: variables.$error;
                border-radius: 0px 12px 12px 0px;
                font-size: 12px;
                color: variables.$white;
                @include mixin.flexCenter;
                font-weight: 700;
            }

            .top_off {
                @include mixin.absolute(0, 0, 0, 10px);
                width: 25px;
                height: 20px;

                // span {
                //     position: relative;
                //     border-radius: 0;
                //     font-size: 10px;
                //     font-weight: 500;
                //     @include mixin.itemsCenter;
                //     width: 100%;
                //     height: 100%;
                //     text-align: center;
                //     // background-color: variables.$blue;
                //     background-image: url("../../../services/utils/svg/Vector\ 4.svg");
                //     background-size: 100%;
                //     background-repeat: no-repeat;
                //     background-position: bottom;
                //     transform: rotate(45deg);
                // }
                span {
                    position: relative;
                    border-radius: 0;
                    font-size: 8px;
                    font-weight: 900;
                    @include mixin.itemsCenter;
                    justify-content: center;
                    text-align: center;
                    padding-top: 3px;
                    width: 100%;
                    height: 100%;
                    // line-height: 2;
                    background-color: variables.$blue;
                    z-index: 1;

                    &::before {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        top: 19px;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        background-image: url("../../../services/utils/svg/Vector\ 4.svg");
                        background-size: 100%;
                        background-repeat: no-repeat;
                        transform: rotate(0deg);
                    }
                }
            }

            .flash_off {
                @include mixin.absolute(0, 0, 0, 50%);
                transform: translateX(-50%);
                height: 16px;
                width: 72px;

                span {
                    border-radius: 0 0 6px 6px;
                    font-size: 10px;
                    font-weight: 500;
                    width: 72px;
                    height: 16px;
                    position: relative;
                    background-color: transparent;
                    z-index: 1;
                    background-image: url("../../../services/utils//svg/flash_sale_bg_1.svg");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    svg {
                        width: 4.625px;
                        height: 9px;
                        margin-right: 4px;
                        height: auto;
                        @include mixin.itemsCenter;
                    }
                }
            }

            img {
                aspect-ratio: 1/1;
                object-fit: contain;
            }
        }

        .product_details {
            @include mixin.flexColBetween;
            height: 100%;
            // padding-top: 10px;
            word-wrap: break-word;

            h3 {
                max-width: 100%;
                font-size: 12px;
                font-weight: 500;
                color: variables.$dark;
                // margin-bottom: 15px;

                span {
                    color: variables.$dark;
                }

                .out_off_stock {
                    font-size: 10px;
                    color: variables.$error;
                    padding-left: 0;
                    background-color: variables.$dark;
                    color: variables.$white;
                    border-radius: 4px;
                    padding: 2px 5px;
                    display: inline-block;
                    text-transform: capitalize;
                }

                .product_top_option {
                    padding: 8px 0;
                }
                // .delivery_time {
                //     span {
                //         color: variables.$dark;
                //         font-size: 10px;
                //     }
                // }
            }
            .rating {
                display: flex;
                align-items: center;
                gap: 2px;
                .total {
                    color:  variables.$ltGreyScale600;
                    font-size: 10px;
                }
            }
            .price {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-end;
                justify-content: space-between;

                div {
                    width: auto;
                }

                del {
                    color: #a0aec0;
                    font-weight: 400;
                    font-size: 10px;
                }
                b {
                    color: #fd6a6a;
                    font-weight: 700;
                    font-size: 12px;
                }
                h4 {
                    font-weight: 600;
                    font-size: 12px;
                    margin: 0;
                    // margin-top: 5px;
                }

                .addBtn {
                    margin-top: 5px;
                    margin-left: 2px;
                }
            }
        }
    }
    .padding0 {
        padding: 0 0 10px 0;
    }
    .paddingX {
        padding: 0;
    }

    .product_wrap_ofs {
        opacity: 0.6;
    }
}

.product_wrap_old {
    border: 1px solid #eeeff2;
    background: #ffffff;
    // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);

    .product {
        .img_wrap {
            border: none;
            border-radius: 0;
            div,
            img {
                border-radius: 0;
            }
        }
    }
    .paddingX {
        padding: 0 10px;
    }
}

.product_list_wrp {
    margin-bottom: 10px;
    @include mixin.itemsCenter;

    width: 100%;
    .list_btn_wrp {
        width: 100%;
    }
    a {
        color: variables.$dark;

        &:last-child {
            width: 100%;
        }

        .img_wrap {
            width: 50px;
            height: 50px;
            // border-radius: 100%;
            @include mixin.borderRadius;
            @include mixin.border;
            overflow: hidden;
            @include mixin.itemsCenter;
            margin-right: 10px;

            img {
                object-fit: contain;
                aspect-ratio: 1;
            }
        }
    }
    .top {
        @include mixin.flexBetween;
        width: 100%;
        align-items: flex-end;

        & > div {
            @include mixin.itemsCenter;
            font-weight: 600;
            font-size: 14px;

            .label {
                font-size: 12px;
                font-weight: 400;
                color: variables.$greyD;
            }
        }
        h4 {
            font-weight: 600;
            font-size: 12px;
            margin: 0;
        }

        .price {
            @include mixin.flexColumn;

            span {
                @include mixin.itemsCenter;
                flex-wrap: wrap;
                justify-content: flex-end;
                font-size: 10px;

                del {
                    color: variables.$greyD;
                    font-weight: 400;
                    line-height: 0;
                }
                b {
                    color: variables.$error;
                }
            }
        }
    }
    .bottom {
        @include mixin.itemsCenter;
        justify-content: space-between;
        margin-top: 5px;

        p {
            margin: 0;
            font-size: 12px;
            font-weight: 400;
        }
        span {
            color: variables.$greyD;
            font-size: 12px;
            font-weight: 400;
        }
        .out_off_stock {
            color: variables.$error;
        }
        .out_off_stock_success {
            color: variables.$orange;
        }

        .info {
            @include mixin.flexColBetween;
        }
    }
}

.alter_native_brand_design {
    margin-bottom: 15px;
    .list_btn_wrp {
        .bottom {
            padding-top: 0px;
        }
    }
    .top {
        .price {
            h4 {
                font-weight: 500;
            }
        }
        & > div {
            p {
                font-size: 12px;
                color: variables.$primary;
            }
        }
    }
}

.product_list_btn_wrp {
    border-bottom: 1px solid variables.$grey200;
    margin-bottom: 0;
    padding: 10px 0;
    .list_btn_wrp {
        width: 100%;
    }

    .top {
        // margin-bottom: 5px;
        & > div {
            font-size: 12px;
            font-weight: 500;
            @include mixin.flexColumn;
            justify-content: space-between;
            align-items: flex-start;
        }
        .label {
            font-size: 10px !important;
            font-weight: 400;
            padding: 0;
            color: variables.$greyD;
            display: block;
            margin-top: 5px;
        }

        .price {
            h4 {
                text-align: end;
                font-size: 10px;
                // margin: 2px 0;
                margin-top: 8px;
            }
        }

        .search_content_icons {
            @include mixin.itemsCenter;
            margin-bottom: 3px;
            justify-content: flex-end;
            width: 100%;

            .icon {
                margin-right: 5px;
            }
            .icon_list {
                @include mixin.itemsCenter;
                margin-right: 3px;
                svg {
                    width: 14px !important;
                    height: 14px !important;
                }
            }

            svg {
                width: 12px !important;
                height: 12px !important;
            }
        }
    }
    a {
        .img_wrap {
            width: 73px;
            height: 73px;
            border: 1px solid variables.$grey200;
            position: relative;
            border-radius: 3px;

            .top_off {
                @include mixin.absolute(0, 0, 0, 4px);
                width: 25px;
                height: 20px;

                span {
                    position: relative;
                    border-radius: 0;
                    font-size: 8px;
                    font-weight: 900;
                    @include mixin.itemsCenter;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    color: variables.$white;
                    padding: 8px 5px 5px 5px;
                    // line-height: 2;
                    background-color: variables.$blue;
                    z-index: 1;

                    &::before {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        top: 20px;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        background-image: url("../../../services/utils/svg/Vector\ 4.svg");
                        background-size: 100%;
                        background-repeat: no-repeat;
                        transform: rotate(0deg);
                    }
                }
            }
            .search_content_top_off {
                width: 18px;
                height: 17px;

                span {
                    font-size: 6px;
                    padding: 0px 0px 0px 0px;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 15px;
                    }
                }
            }

            .flash_off {
                @include mixin.absolute(0, 0, 0, 50%);
                transform: translateX(-50%);
                height: 12px;
                width: 50px;
                @include mixin.itemsCenter;

                span {
                    border-radius: 0 0 6px 6px;
                    font-size: 7px;
                    font-weight: 500;
                    width: 50px;
                    height: 12px;
                    position: relative;
                    background-color: transparent;
                    z-index: 1;
                    background-image: url("../../../services/utils//svg/flash_sale_bg_1.svg");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    @include mixin.itemsCenter;
                    justify-content: center;
                    color: variables.$white;

                    svg {
                        width: 3.084px; 
                        height: 6px;
                        margin-right: 3px;
                        height: auto;
                        @include mixin.itemsCenter;
                    }
                }
            }

            .search_content_out_of_stock {
                position: absolute;
                z-index: 9;
                background-color: rgba($color: rgba(238, 239, 242, 1), $alpha: 0.8);
                width: 100%;
                height: 100%;
                @include mixin.itemsCenter;
                justify-content: center;
                padding: 5px;

                span {
                    background-color: #718096;
                    color: variables.$white;
                    font-size: 8px;
                    font-weight: 600;
                    @include mixin.itemsCenter;
                    width: 100%;
                    justify-content: center;
                    border-radius: 3px;
                }
            }
        }
    }

    .bottom {
        margin-top: 0px;
        align-items: flex-start;
        // flex-wrap: wrap;

        p,
        span {
            font-size: 10px;
        }

        a {
            height: 100%;
            p {
                margin: 0;
                margin-bottom: 5px;
            }
        }
    }
}

.product_wrap_horizontal {
    .product {
        padding: 0;
        .img_wrap {
            @include mixin.itemsCenter;
            height: 100%;

            span {
                @include mixin.absolute(auto, auto, 10px, 0);
            }
        }

        .product_details {
            margin-left: 5px;
            padding: 10px;

            .price {
                position: relative;
                display: flex;
                // flex-direction: column;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: flex-start;

                del {
                    color: #a0aec0;
                    font-weight: 400;
                    font-size: 10px;
                }
                b {
                    color: #fd6a6a;
                    font-weight: 700;
                    font-size: 12px;
                }
                h4 {
                    font-weight: 600;
                    font-size: 12px;
                    margin: 0;
                    margin-top: 5px;
                }
            }
        }
    }
}

// .product_type_delivery {
//     svg {
//         width: 10px !important;
//         height: 10px !important;
//     }
// }

@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.shopping_cart_margin_top {
    margin-top: 12px;
}
.shopping_cart {
    @include mixin.borderRadius;
    border: 1px solid var(--greyscale-300, #eeeff2);
    // padding: 10px;
    background-color: variables.$white;
    .error_info_wrap_wrap {
        padding: 10px;
        border-bottom: 1px solid variables.$grey200;
        .error_info_warn {
            background-color: rgba($color: variables.$blue, $alpha: 0.1);
            padding: 10px;
            display: flex;
            @include mixin.borderRadius;

            span {
                font-size: 12px;
                font-weight: 400;
            }
            b {
                font-weight: 500;
            }

            svg {
                margin-top: 2px;
                display: block;
                color: variables.$blue;
                width: 14px;
                height: 14px;
                margin-right: 10px;
            }
        }
    }

    .cart_header {
        @include mixin.borderRadius;
        overflow: hidden;
        h3 {
            font-size: 14px;
            font-weight: 600;
            background-color: rgba($color: variables.$primary, $alpha: 0.1);
            padding: 10px;
            background: url(../../../services/utils/svg/cart-bg/available.svg) bottom;
            background-repeat: repeat-x;
            // background-size: cover;
        }
    }
    .shopping_cart_not_available {
        // margin-bottom: 10px;
        h3 {
            background-color: rgba($color: variables.$error, $alpha: 0.1);
            background: url(../../../services/utils/svg/cart-bg/not_available.svg) bottom;
            span {
                svg {
                    color: variables.$error;
                    width: 24px;
                    height: 24px;
                }
            }
            div {
                display: flex;
                margin-left: 5px;
                .del_icon {
                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }
                span {
                    margin-right: 5px;
                    display: block;
                    @include mixin.itemsCenter;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    @media only screen and (max-width: 520px) {
        .shopping_cart_not_available,
        .cart_header {
            h3 {
                background-size: cover;
            }
        }
    }
}

.cart_type_wrapper {
    h2 {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
        padding: 5px 0;
        text-transform: capitalize;
    }
}

.wrap_card_product {
    .card_product {
        border-bottom: 1px solid variables.$grey200;
        padding: 10px;
        // @include mixin.itemsCenter;
        display: flex;
        // width: 100%;

        .cart_stock_details {
            display: flex;
            width: 100%;
        }

        &:last-child {
            border: none;
        }
        .img_wrap {
            min-width: 75px;
            min-height: 75px;
            max-width: 75px;
            max-height: 75px;
            overflow: hidden;
            // border-radius: 100%;
            position: relative;
            @include mixin.borderRadius;
            border: 1px solid variables.$grey200;
            // @include mixin.itemsCenter;
            z-index: 1;

            img {
                width: 100%;
                height: 100%;
                aspect-ratio: 1;
                object-fit: contain;
            }

            .top_off {
                @include mixin.absolute(0, 0, 0, 10px);
                width: 22px;
                height: 17px;

                span {
                    position: relative;
                    border-radius: 0;
                    font-size: 7px;
                    font-weight: 900;
                    @include mixin.itemsCenter;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    // padding: 8px 5px 5px 5px;
                    padding-top: 3px;
                    color: variables.$white;
                    background-color: variables.$blue;
                    z-index: 1;

                    &::before {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        top: 17px;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        background-image: url("../../../services/utils/svg/Vector\ 4.svg");
                        background-size: 100%;
                        background-repeat: no-repeat;
                        transform: rotate(0deg);
                    }
                }
            }
            .flash_off {
                @include mixin.absolute(0, 0, 0, 50%);
                transform: translateX(-50%);
                height: 12px;
                width: 50px;
                @include mixin.itemsCenter;

                span {
                    border-radius: 0 0 6px 6px;
                    font-size: 7px;
                    font-weight: 500;
                    width: 50px;
                    height: 12px;
                    position: relative;
                    background-color: transparent;
                    z-index: 1;
                    background-image: url("../../../services/utils//svg/flash_sale_bg_1.svg");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    @include mixin.itemsCenter;
                    justify-content: center;
                    color: variables.$white;

                    svg {
                        width: 3.084px;
                        height: 6px;
                        margin-right: 3px;
                        height: auto;
                        @include mixin.itemsCenter;
                    }
                }
            }

            .search_content_out_of_stock {
                position: absolute;
                top: 0;
                z-index: 9;
                background-color: rgba($color: rgba(238, 239, 242, 1), $alpha: 0.8);
                width: 100%;
                height: 100%;
                @include mixin.itemsCenter;
                justify-content: center;
                padding: 5px;

                span {
                    background-color: #718096;
                    color: variables.$white;
                    font-size: 8px;
                    font-weight: 600;
                    @include mixin.itemsCenter;
                    width: 100%;
                    justify-content: center;
                    border-radius: 3px;
                }
            }
        }

        .content {
            padding-left: 10px;
            width: 100%;
            & > div {
                height: 100%;
            }
        }
        .details_wrap {
            @include mixin.flexBetween;
            flex-direction: column;
            height: 100%;

            a {
                color: variables.$dark;
                @include mixin.flexColumn;
                // justify-content: space-between;
                justify-content: space-evenly;
            }
            // width: 100%;

            .content_details_wrp {
                display: flex;
                justify-content: space-between;
                // flex-direction: column;
            }

            .details {
                max-width: 60%;

                h3 {
                    font-size: 12px;
                    font-weight: 600;
                    margin: 0;
                }
                span {
                    font-size: 10px;
                    color: variables.$greyD;
                }
                p {
                    font-size: 10px;
                    margin: 0;
                    @include mixin.itemsCenter;
                    svg {
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                    }
                }
            }
            .actions {
                max-width: 50%;
                @include mixin.flexColumn;
                justify-content: space-between;
                height: 100%;

                .icon {
                    @include mixin.itemsCenter;
                    justify-content: flex-end;

                    .rx_req_ic {
                        padding-right: 10px;
                    }
                    span {
                        @include mixin.itemsCenter;
                        margin-right: 5px;
                        @include mixin.lastChildMargin;

                        svg {
                            @include mixin.itemsCenter;
                            color: variables.$grey300;
                            width: 25px;
                            height: 25px;
                        }
                    }
                    .delete_icon {
                        width: 18px;
                        height: 18px;
                    }

                    .delivery_icon {
                        @include mixin.itemsCenter;
                        margin-right: 5px;
                    }
                }
                .add_to_cart_btn {
                    display: flex;
                    justify-content: flex-end;
                }
                .price {
                    margin: 3px 0;
                    @include mixin.itemsCenter;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                    del {
                        font-size: 10px;
                        color: variables.$greyD;
                    }
                    b {
                        font-size: 12px;
                        margin-left: 5px;
                    }
                }
                // @media only screen and (max-width: 320px) {
                //     .price {
                //         @include mixin.flexColumn;
                //         justify-content: flex-end;
                //         margin-top: 5px;

                //         del {
                //             @include mixin.flexConEnd;
                //         }
                //         b {
                //             @include mixin.flexConEnd;
                //         }
                //     }
                // }

                .cart_tags {
                    @include mixin.flexBetween;

                    button {
                        margin-left: 5px;
                    }
                }
                @media only screen and (max-width: 320px) {
                    .cart_tags {
                        width: 100%;
                        @include mixin.flexColumn;
                        align-items: flex-end;
                        button {
                            margin-top: 5px;
                        }
                    }
                }
            }
        }
        .cold_item {
            color: variables.$error;
            font-size: 10px;
            margin: 0;
            margin-top: 10px;
        }
        .cold_item_success {
            color: variables.$primary;
        }

        .bottom {
            & > p {
                color: variables.$error;
                font-size: 10px;
                margin: 0;
            }
            .regular {
                svg {
                    color: variables.$primary;
                }
            }

            .details {
                display: flex;
            }
        }
    }
    .with_stock_details {
        flex-direction: column;

        .stock_details_info {
            font-size: 14px;
            font-weight: 400;

            span {
                color: variables.$orange;
                margin-top: 5px;
                display: inline-block;
            }
        }
    }
}

.alert_icon_design {
    @include mixin.flexCenter;
    margin-top: 20px;
    span {
        @include mixin.flexCenter;

        background-color: rgba($color: variables.$error, $alpha: 0.1);
        padding: 5px;
        width: 58px;
        height: 58px;
        border-radius: 100%;

        svg {
            color: variables.$error;
        }
    }
}

.sell_all_unavailable {
    font-size: 12px;
    font-weight: 500;
    color: variables.$primary;
    @include mixin.itemsCenter;
    padding: 10px;
    justify-content: flex-end;

    svg {
        margin-left: 5px;
        rotate: 180deg;
        width: 16px;
        height: 16px;
    }
}

@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.add_btn_wrap {
    position: relative;

    .add_btn {
        border-radius: 6px;
        display: block;
        // border: none;
        font-size: 12px;
        @include mixin.flexCenter;
        font-weight: 600;
        // background-color: variables.$primary;
        // max-width: 50px;
        // padding: 8px 15px;
        padding: 6px 10px;
        min-width: 62px;
        color: variables.$white;
        // text-transform: uppercase;
        white-space: nowrap;

        // new issue
        background-color: rgba($color: variables.$white, $alpha: 1);
        border: 1px solid variables.$primary;
        span {
            font-weight: 600 !important;
        }

        svg {
            width: 18px;
            height: 18px;
            margin-right: 2px;
            color: variables.$white;
        }

        // .request_stock {
        //     min-width: 100%;
        //     display: block;
        //     @include mixin.ellipsis;
        // }
    }
    .quantity_btn {
        // background-color: transparent !important;
        background-color: variables.$primary;
        // color: variables.$greyD;
        border: 1px solid variables.$primary !important;
        justify-content: space-around;
        text-transform: none;
        // max-width: 150px !important;
        padding: 6px 10px;

        span {
            // font-weight: 600;
            color: variables.$white !important;
            overflow: hidden;
            max-width: 100%;
            @include mixin.ellipsis;
        }

        svg {
            color: variables.$white !important;
            rotate: -90deg;
        }
    }
    .only_qty {
        font-weight: 600 !important;
        // color: variables.$dark !important;

        span {
            @include mixin.itemsCenter;
            justify-content: space-between;
        }

        svg {
            width: 10px;
            height: 10px;
            @include mixin.itemsCenter;
            color: variables.$dark;
            rotate: 0deg;
            margin-left: 5px;
        }
    }
    // .requested_disabled {
    //     opacity: 0.5;
    // }
    .out_off_stock {
        // background-color: transparent !important;
        // border: 1px solid variables.$error !important;
        // color: variables.$error !important;
        // max-width: 75px;
        // font-weight: normal;

        span {
            // color: variables.$error !important;
            color: variables.$primary !important;
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }
    .cart_btn {
        // color: variables.$white;
        color: variables.$primary;

        @include mixin.itemsCenter;
        width: 100%;
        justify-content: center;

        span {
            overflow: hidden;
            max-width: 100%;
            @include mixin.ellipsis;
            // color: variables.$white;
            color: variables.$primary !important;
        }

        svg {
            // color: variables.$white;
            color: variables.$primary;

            width: 15px;
            height: 15px;
            margin: 0;
            margin-right: 5px;
        }
    }
    .req_sent {
        // border: 1px solid variables.$grey500 !important;
        // border: none;
        opacity: 0.7;
        // max-width: 85px;
        // padding: 0;
        background-color: transparent;
        display: flex;
        // justify-content: flex-end;
        width: 100%;
        margin-left: auto;

        span {
            color: variables.$grey500 !important;
            @include mixin.flexCenter;
            width: 100%;

            svg {
                @include mixin.flexCenter;
                max-width: 18px;
                max-height: 18px;
                min-width: 18px;
                min-height: 18px;
                margin-left: 5px;
            }
        }
    }
    .trans_parent_req_sent {
        border: none;
        justify-content: flex-end;
        padding: 0;
    }
    .outlineButton {
        max-width: 130px;
        min-width: 130px;
        padding: 12px 15px;
        background-color: variables.$primary;
        span {
            color: variables.$white !important;
            text-transform: capitalize;
            font-weight: 600;
        }
    }
    .single_product_btn {
        min-height: 40px !important;
        min-width: 125px;
    }

    .qty_old {
        @include mixin.itemsCenter;
        svg {
            rotate: 0deg;
            width: 14px;
            height: 14px;
            margin-left: 10px;
        }
    }
    .down_icon {
        @include mixin.itemsCenter;
        svg {
            @include mixin.itemsCenter;
            width: 10px !important;
            height: 10px !important;
        }
    }
    .outline_button_auto_wdt {
        max-width: auto;
        min-width: auto;
        padding: 6px 10px;
    }

    .search_notify {
        border: 1px solid variables.$primary;
        max-width: 37px !important;
        min-width: 37px !important;
        min-height: 30px;
    }
    .single_product_btn_req_sent {
        min-width: 50px !important;
    }

    .quantity_btn_outline {
        background-color: transparent;
        span {
            color: variables.$primary !important;
            svg {
                color: variables.$primary !important;
            }
        }
    }
}

.modal_design {
    max-height: 315px !important;
    max-width: 195px !important;
    padding-top: 10px !important;
    border-radius: 4px !important;

    & > h3 {
        padding: 0 10px 10px 10px !important;
        // line-height: 0;
        font-size: 12px !important;
        font-weight: 600 !important;

        svg {
            color: #718096;
        }
    }

    .top {
        li {
            border-bottom: 1px solid variables.$grey200;
            display: block;
            padding: 17px 10px;
            font-size: 12px;
            @include mixin.itemsCenter;
            font-weight: 400;
            padding-top: 0;

            &:first-child {
                color: variables.$error;
                font-weight: normal;
                padding-top: 17px;
                font-weight: 500;
            }
        }
    }

    .options {
        // max-height: 275px;
        // max-height: 235px;
        overflow-x: hidden;
        overflow-y: scroll;
        padding: 0;
        margin: 0;
        // margin-top: 10px;

        &::-webkit-scrollbar {
            background-color: rgba($color: variables.$primary, $alpha: 0.1);
            width: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgba($color: variables.$primary, $alpha: 1);
            width: 5px;
            border-radius: 4px;
        }
        scrollbar-width: 5px;
        scrollbar-color: rgba($color: variables.$primary, $alpha: 0.1);

        li {
            display: block;
            border-bottom: 1px solid variables.$grey200;
            // margin-right: 5px;
            padding: 17px 10px;
            font-size: 12px;
            font-weight: 400;

            &:hover {
                background-color: rgba($color: variables.$primary, $alpha: 0.2);
            }
        }
        .active {
            background-color: rgba($color: variables.$primary, $alpha: 0.1);
            color: variables.$primary;
            @include mixin.flexBetween;
            align-items: center;
            padding: 12.5px 10px;
        }
    }

    @media only screen and (min-height: 375px) {
        .product_crt_hgt {
            background-color: variables.$white !important;
        }

        .product_no_crt_hgt {
            background-color: variables.$white !important;
        }
    }
}
.remove_item_off {
    max-height: 290px !important;
    max-width: 195px !important;
}

@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.custom_select {
    position: relative;
    // height: 222px;
}

.select_selected {
    padding: 9px;
    cursor: pointer;
    border: 1px solid rgba($color: variables.$grey300, $alpha: 0.8);
    @include mixin.flexBetween;
    align-items: center;
    font-size: 12px;
    @include mixin.borderRadius;
    color: variables.$grey300;

    svg {
        width: 20px;
        height: 20px;
        @include mixin.itemsCenter;
        color: variables.$dark;
    }
    margin-bottom: 2px;

    .arrow_right {
        rotate: -90deg;
        svg {
            color: variables.$primary !important;
        }
    }
}

.select_active {
    color: variables.$dark;
    font-size: 12px;
    font-weight: 500;
}
.select_active_ {
    color: variables.$primary;
    border: 1px solid variables.$primary;

    svg {
        color: variables.$primary;
    }
}
.select_option {
    // @include mixin.absolute(35px, 0, 0, 0);
    background-color: variables.$white;
    @include mixin.borderRadius;
    border: 1px solid variables.$grey300;
    // padding: 10px;
    max-height: 200px;
    height: 200px;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    display: none;
    z-index: 999;
    position: relative;

    .list {
        font-size: 12px;
        padding: 10px 15px;

        // &:nth-child(2) {
        //     padding-bottom: 0 !important;
        // }
    }
    .remove {
        color: variables.$error;
        border-bottom: 1px solid variables.$grey200;
    }

    .active {
        color: variables.$primary;
        background-color: rgba($color: variables.$primary, $alpha: 0.1);
        @include mixin.itemsCenter;
        justify-content: space-between;
        padding: 8px 15px;

        svg {
            margin-left: 10px;
            @include mixin.itemsCenter;
        }
    }
    .search_box_wrp {
        padding-bottom: 50px;
        overflow: hidden;
        width: 100%;

        .search_box {
            position: fixed;
            background-color: variables.$white;
            width: 100%;
            // top: 0;
            padding: 10px 15px;
            overflow: hidden;

            label {
                @include mixin.itemsCenter;
                border-radius: 6px !important;
                @include mixin.borderRadius;
                @include mixin.border;

                input {
                    border: none;
                    padding: 10px !important;
                    height: auto;
                }
                span {
                    @include mixin.itemsCenter;
                    padding: 10px;
                    height: 100%;
                    svg {
                        width: 15px;
                        height: 15px;
                        color: variables.$grey500;
                    }
                }
            }
        }
    }

    .no_results {
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        padding: 10px;
    }
}
.select_open {
    display: block;
}
.open_border_color {
    border: 1px solid variables.$primary;
}

.modal_design {
    max-height: 270px !important;
    // max-width: 200px !important;

    .select_option {
        border: none;
        // padding: 0 5px;
    }

    // .modal_child {

    // }
}
